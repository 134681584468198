import React, { FC, CSSProperties } from 'react';
import { Space } from 'antd';
import { BucketStep } from '@apps/purchases/services/buckets';
import purchaseStepIconMapper from '../mainMenu/purchaseStepIconMapper';
import purchaseStatusColors, {
  PurchaseOperationStatus,
} from '../mainMenu/purchaseStatusColors';
import styled from 'styled-components';

const StatusIconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  value?: string;
  data?: {
    stepType: BucketStep;
    status: string;
    alreadyProduct: boolean;
  };
}

const getStepStatus = (
  currentStep: BucketStep,
  checkStep: BucketStep,
  status: string,
): PurchaseOperationStatus => {
  if (status === 'cancelled') return 'cancelled';

  const steps: string[] = [
    'draft',
    'ordered',
    'proforma',
    'invoiced',
    'delivered',
  ];
  const currentIndex = steps.indexOf(currentStep);
  const checkIndex = steps.indexOf(checkStep);

  if (currentIndex === checkIndex) return 'inProgress';
  if (currentIndex > checkIndex) return 'done';
  return 'empty';
};

const PurchaseStepsStatusRenderer: FC<Props> = ({ data }) => {
  if (!data) return null;

  const { stepType, status, alreadyProduct } = data;
  const steps: string[] = [
    'draft',
    'ordered',
    'proforma',
    'invoiced',
    'delivered',
  ];

  return (
    <Space size="small">
      {steps.map((step, index) => {
        const StepIcon: FC<{ style: CSSProperties }> =
          purchaseStepIconMapper[step];
        let stepStatus: PurchaseOperationStatus;

        if (step === 'draft') {
          stepStatus = alreadyProduct ? 'done' : 'empty';
        } else {
          stepStatus = getStepStatus(stepType, step as BucketStep, status);
        }

        const color = purchaseStatusColors[stepStatus];

        return (
          <StatusIconContainer key={index} style={{ backgroundColor: color }}>
            <StepIcon style={{ color: '#fff' }} />
          </StatusIconContainer>
        );
      })}
    </Space>
  );
};

export default PurchaseStepsStatusRenderer;
