import { ApiResponse, GetParams } from '@apps/weekplan/services';
import { PURCHASES_ACTION } from '@apps/purchases';
import { Product, ProductPrice } from '@apps/products/services';
import { CSSProperties } from 'react';
import { DeliveryTermsType } from '@apps/purchases/services/deliveryTerms';
import { PaymentTerm } from '@apps/purchases/services/paymentTerms';
import { Document } from '@apps/documents/service';
import { CompanyContact, PersonContact } from '@apps/contacts/services';
import { BucketStatus, BucketStep } from '@apps/purchases/services/buckets';

export type ProductWithPrice = {
  ecosystem?: string;
  type?: string;
  netPrice?: number;
  tax?: number;
  taxValue?: number;
  discountedPrice?: number;
  grossPrice?: number;
  totalNetPrice?: number;
  totalGrossPrice?: number;
  discount?: number;
  saleDetails?: Product['saleDetails'];
  id?: string;
  key: number;
  productNumber?: string;
  productId?: string;
  productName?: string;
  amount?: number;
  price?: number;
  salesUnit?: Product['saleDetails']['salesUnit'];
  taxCategory?: Product['saleDetails']['taxCategory'];
  taxCategories?: Product['saleDetails']['taxCategory'][];
  prices?: ProductPrice[];
  description?: ProductPrice['description'];
  isDirty?: boolean;
  commissionFactor?: number; // in %
};

export type LayoutSection = {
  tooltip?: string;
  style?: CSSProperties;
  field: string;
  readOnly?: boolean;
  type?: 'inputText' | 'inputNumber' | 'select' | 'datePicker';
};

export type PageLayout = LayoutSection[];

export type Purchase = {
  id?: string;
  ecosystem: string;
  buyerId: string;
  contact: any;
  products: ProductWithPrice[];
  contactSnapshot: any;
  timestamp?: any;
  deliveryTerms: DeliveryTermsType | null;
  paymentTerms: PaymentTerm | null;
  paymentStatus:
    | 'running'
    | 'cancelled'
    | 'expired'
    | 'error'
    | 'refused'
    | 'paid';
  shipmentStatus?:
    | 'pending'
    | 'cancelled'
    | 'delivered'
    | 'error'
    | 'delayed'
    | 'refused';
  orderId?: string;
};

export type GetApiResponse = (
  params: GetParams,
  action: PURCHASES_ACTION,
) => Promise<ApiResponse> | undefined;

export const PURCHASES_COLLECTION = 'purchases';

export type PurchaseFormValues = Partial<Document> & {
  products: ProductWithPrice[];
  deliveryTerms?: DeliveryTermsType;
  paymentTerms?: PaymentTerm;
  stepId?: string;
  saleId?: string;
  orderId?: string;
};

export type ProdTotalsSummary = {
  total: number;
  totalNetPrice: number;
  totalDiscountedPrice: number;
  taxes: Record<any, number>;
};

export type IndividualPurchaseRequest = {
  id?: string;
  ecosystem: string;
  timestamp?: number;
  productName?: string;
  productNumber?: string;
  productId?: string;
  alreadyProduct?: boolean;
  toBeStocked: boolean;
  preferredSupplierId?: string;
  preferredSupplierSnapshot?: Partial<PersonContact & CompanyContact>;
  shortDescription: string;
  description: string;
  unit: string;
  unitAmount?: number;
  tax?: number;
  creator: User;
  takeBy?: User | null;
  needDate: string;
  needAmount: number;
  link?: string;
  creationDate: string;
  project?: string;
  costCenter?: string;
  status?: BucketStatus;
  stepType?: BucketStep;
  bucketId?: string;
  relatedDocumentId?: string;
};
