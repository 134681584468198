import React, { FC, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { Document } from '@apps/documents/service/namespace';
import { getUnconnectedDeliveryNotes } from '@apps/documents/service';
import moment from 'moment';
import { getContactName } from '@apps/documents/service';
import { useFormContext } from '@components/FormWithReminder/useFormContext';

const DeliveryNoteSelect: FC = () => {
  const { getFieldValue } = useFormContext();
  const [deliveryNotes, setDeliveryNotes] = useState<Document[]>([]);
  const supplierId = getFieldValue('supplier');
  const ecosystem = getFieldValue('ecosystem');

  useEffect(() => {
    if (supplierId && ecosystem) {
      getUnconnectedDeliveryNotes(ecosystem, supplierId)
        .then(setDeliveryNotes)
        .catch(console.error);
    } else {
      setDeliveryNotes([]);
    }
  }, [supplierId, ecosystem]);

  return (
    <Form.Item
      name="deliveryNote"
      label="Delivery Note"
      rules={[{ required: true }]}
    >
      <Select
        disabled={!supplierId || !ecosystem}
        placeholder="Select delivery note..."
        options={deliveryNotes.map((doc) => ({
          value: doc.id,
          label: `${doc.documentNumber || doc.docNumber || ''} - ${moment(
            doc.creationDate,
          ).format('DD.MM.YYYY')} - ${
            doc.relatedContactSnapshot
              ? getContactName(doc.relatedContactSnapshot)
              : ''
          }`,
        }))}
      />
    </Form.Item>
  );
};

export default DeliveryNoteSelect;
