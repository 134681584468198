import React, { FC } from 'react';
import { Bucket } from '@apps/purchases/services/buckets';
import { Tag } from 'antd';
import { useIntl } from 'react-intl';

type Props = {
  data?: Bucket;
  value: Bucket['status'];
};

const colors: Record<Bucket['status'], string> = {
  draft: '#1890ff',
  cancelled: '#faad14',
  sent: '#0e7d40',
  done: '#0e7d40',
  delivered: '#0e7d40',
  partlyDelivered: '#faad14',
};

const BucketStatusRenderer: FC<Props> = ({ value }) => {
  const intl = useIntl();
  return value ? (
    <Tag color={colors[value] || colors.cancelled}>
      {value
        ? intl.formatMessage({
            id: `${value}`,
            defaultMessage: value,
          })
        : ''}
    </Tag>
  ) : null;
};

export default BucketStatusRenderer;
