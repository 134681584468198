import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { Spin, Form, Button, Space, message } from 'antd';
import {
  Bucket,
  BucketStep,
  updateBucket,
} from '@apps/purchases/services/buckets';
import AppConfig, { PURCHASES_ACTION } from '@apps/purchases';
import { Document, postDocument } from '@apps/documents/service';
import { useForm } from 'antd/lib/form/Form';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import SelectedEcosystem from '@components/SelectedEcosystem';
import {
  checkUserPermissions,
  getActiveEcosystemsForApp,
} from '@components/EcosystemIndicator/store';
import { RootStore, rootStore } from '@core/store';
import {
  ButtonContainer,
  PageViewDrawer,
  RightColumn,
  ViewLayout,
} from '@apps/purchases/mainMenu/components/styles.sc';
import { ArrowRightOutlined } from '@ant-design/icons';
import PurchaseCreatorSettings from '@apps/purchases/mainMenu/components/PurchaseCreatorSettings';
import BucketStatus from '@apps/purchases/mainMenu/operationSteps/operationStep/BucketStatus';
import BucketStepType from '@apps/purchases/mainMenu/operationSteps/operationStep/BucketStepType';
import intl from '../../../../../../intl';
import PagePreview from '@apps/purchases/mainMenu/operationSteps/operationStep/PagePreview';
import getPurchasesRangeOfNumbersByEcosystem from '@apps/purchases/services/rangeOfNumbers/getPurchasesRangeOfNumbersByEcosystem';
import { supportedFileTypes } from '@apps/purchases/settings/rangeOfNumbers/consts';
import moment from 'moment/moment';
import { RangeOfNumbersType } from '@services/rangeOfNumbers';
import { useHistory } from 'react-router-dom';
import { updateIndividualPurchaseRequest } from '@apps/purchases/services/individualPurchaseRequests';
import { useSelector } from 'react-redux';
import createTemplate from '@apps/purchases/mainMenu/components/pdfTemplates/createTemplate';
import { blobToFile } from '@apps/utils';
import storage from '@services/Firebase/Storage';
import { firebase } from '@services/Firebase';
import stepTypeToDocType from '@apps/purchases/mainMenu/operationSteps/operationStep/stepTypeToDocType';
import addPurchasesRangeOfNumbers from '@apps/purchases/services/rangeOfNumbers/addPurchasesRangeOfNumbers';
import createRelation from '@services/entityRelations/createRelation';
import { emitCustomEvent } from '@core/services';

const { Item } = Form;

type Props = {
  bucketId?: string;
  bucket?: Bucket | null;
  ecosystemId?: string;
  action: PURCHASES_ACTION;
  relatedDocuments?: Document[];
};

type BucketForm = Omit<Bucket, 'creationDate'> & {
  creationDate: moment.Moment;
};

const BucketCreator: FC<Props> = ({
  bucketId,
  bucket,
  ecosystemId: passedEcosystemId,
  relatedDocuments = [],
}) => {
  const asDraft = useRef(false);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<BucketForm | null>(null);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [rangeOfNumbers, setRangeOfNumbers] =
    useState<RangeOfNumbersType | null>(null);

  const activeEcosystems = useSelector((state) =>
    getActiveEcosystemsForApp(
      state as RootStore,
      AppConfig.todixId,
      'create-purchases-requests' as PURCHASES_ACTION,
    ),
  );

  const canProceed = checkUserPermissions(
    rootStore.getState(),
    AppConfig.todixId,
    'update-purchases-requests' as PURCHASES_ACTION,
    passedEcosystemId || '',
  );

  useEffect(() => {
    if (passedEcosystemId) {
      setLoading(true);
      getPurchasesRangeOfNumbersByEcosystem(passedEcosystemId)
        ?.then((el) => {
          if (el !== undefined) {
            setRangeOfNumbers(el);
          } else {
            const initialRange = {
              ecosystem: passedEcosystemId,
              fileTypes: {
                ...Object.keys(supportedFileTypes).reduce(
                  (prev: any, fileType) => {
                    prev[fileType] = {
                      abbreviation: intl.formatMessage({
                        id: `${fileType}.abbreviation`,
                        defaultMessage: `${fileType}.abbreviation`,
                      }),
                      minLength: 6,
                      nextNumber: 1,
                    };
                    return prev;
                  },
                  {},
                ),
              },
            };
            setRangeOfNumbers(initialRange);
          }
        })
        ?.finally(() => {
          setLoading(false);
        });
    }
  }, [passedEcosystemId]);

  const handleSubmit = useCallback(
    async (values: Bucket) => {
      const eco = activeEcosystems.find((eco) => eco.id === passedEcosystemId);
      if (!eco) {
        message.error(
          intl.formatMessage({
            id: 'error.request',
            defaultMessage: 'Error while processing request',
          }),
        );
        return;
      }
      if (!rangeOfNumbers || !canProceed) {
        message.error(
          intl.formatMessage({
            id: 'error.request',
            defaultMessage: 'Error while processing request',
          }),
        );
        return;
      }
      if (asDraft.current) {
        //save as draft - do not create PDF
        if (bucketId) {
          try {
            setLoading(true);
            await updateBucket(bucketId, {
              ...values,
              draftSaved: true,
            });
            //update positions
            const positions = values.positions.map((pos) => {
              return updateIndividualPurchaseRequest(
                pos.id as string,
                {
                  ...pos,
                } as any,
              );
            });
            await Promise.all(positions);
            setLoading(false);
          } catch (error) {
            message.error(error);
            console.error(error);
            setLoading(false);
          }
        }
      } else {
        // save persist and create pdf document
        if (bucketId) {
          try {
            setLoading(true);
            const updatedValues = {
              ...values,
              status: 'done',
              draftSaved: true,
            } as Bucket;
            await updateBucket(bucketId, updatedValues);
            //update positions
            const positions = values.positions.map((pos) => {
              return updateIndividualPurchaseRequest(
                pos.id as string,
                {
                  ...pos,
                  status: 'done',
                } as any,
              );
            });
            await Promise.all(positions);
            form.setFieldsValue({ ...updatedValues });
            const type = stepTypeToDocType[bucket?.stepType as BucketStep];

            await addPurchasesRangeOfNumbers(
              {
                ...rangeOfNumbers,
                fileTypes: {
                  ...rangeOfNumbers?.fileTypes,
                  [type as string]: {
                    ...rangeOfNumbers?.fileTypes?.[type as string],
                    nextNumber: rangeOfNumbers?.fileTypes?.[type as string]
                      ?.nextNumber
                      ? Number(
                          rangeOfNumbers?.fileTypes?.[type as string]
                            ?.nextNumber,
                        ) + 1
                      : 1,
                  },
                },
              },
              passedEcosystemId as string,
              {
                message: false,
              },
            );

            if (bucket?.stepType === 'invoiced') {
              //stop here for invoice - dont create pdf
              emitCustomEvent('bucketSaved', {});
              setLoading(false);
              message.success(
                intl.formatMessage({
                  id: 'bucket.closed',
                  defaultMessage: 'Bucket has been closed',
                }),
              );
              return;
            }

            //create pdf

            const avatar: string = eco && eco.avatarUrl ? eco.avatarUrl : '';

            const doc = await createTemplate({
              ecosystem: eco,
              avatar,
              intl,
              values: updatedValues,
            });

            const fileName = `${passedEcosystemId}_${
              values.docNumber as string
            }`;
            const blob = doc.output('blob');

            const fileToUpload = {
              file: blobToFile(blob, fileName),
              ecosystem: passedEcosystemId,
              fileDetails: {
                format: 'pdf',
                size: blob.size,
              },
            };

            const snapshot = await storage.upload(
              `documents/${fileName}`,
              fileToUpload.file as unknown as Blob,
            );
            const documentUrl = await snapshot?.ref.getDownloadURL();
            const utcDate = moment().utc();
            const user = firebase.auth.currentUser;
            const documentId = await postDocument(
              {
                documentUrl,
                docNumber: `${values.docNumber as string}`,
                referenceNumber: `${(values.referenceNumber as string) || ''}`,
                ecosystem: fileToUpload.ecosystem,
                fileDetails:
                  fileToUpload.fileDetails as Document['fileDetails'],
                type,
                uploadingUser: user?.uid,
                uploadingUserId: user?.uid,
                creatingUser: user?.uid,
                uploadDate: utcDate,
                receivingDate: utcDate,
                issuingDate: utcDate,
                creationDate: utcDate,
                relatedContact: values.relatedContactSnapshot?.id || '',
                relatedContactSnapshot: values.relatedContactSnapshot || {},
                isDraft: false,
                category: 'outgoing',
              },
              {
                message: true,
                messageContent: intl.formatMessage({
                  id: 'document.created',
                  defaultMessage: 'Document has been created',
                }),
              },
            );

            await updateBucket(bucketId, {
              ...updatedValues,
              relatedDocumentId: documentId,
            });

            await createRelation({
              timestamp: utcDate.unix(),
              ecosystem: values.ecosystem as string,
              parentId: bucketId,
              parentType: 'purchase',
              parentRefCollection: 'purchases',
              childId: documentId,
              childType: 'document',
              childRefCollection: 'documents',
            });

            emitCustomEvent('bucketSaved', {});
            setLoading(false);
            message.success(
              intl.formatMessage({
                id: 'bucket.closed',
                defaultMessage: 'Bucket has been closed',
              }),
            );
          } catch (error) {
            message.error(error);
            console.error(error);
            setLoading(false);
          }
        }
      }
    },
    [
      activeEcosystems,
      bucket?.stepType,
      bucketId,
      canProceed,
      form,
      passedEcosystemId,
      rangeOfNumbers,
    ],
  );

  const handleCancel = useCallback(() => {
    form.resetFields();
  }, [form]);

  const goBackToList = useCallback(() => {
    history.push(`app/${AppConfig.todixId}`);
  }, [history]);

  useEffect(() => {
    if (bucket && initialValues === null) {
      const fetchedBucked = {
        ...bucket,
        positions: bucket.positions.map((el, index) => ({
          ...el,
          key: index,
        })),
        creationDate: bucket.creationDate
          ? moment(bucket.creationDate)
          : moment(),
        deliveryTerms: bucket.deliveryTerms,
        paymentTerms: bucket.paymentTerms,
        referenceNumber: bucket.referenceNumber || '',
        deliveryAddress: bucket.deliveryAddress || '',
        freeTextStart: bucket.freeTextStart || '',
        freeTextEnd: bucket.freeTextEnd || '',
        draftSaved: bucket.draftSaved,
      };
      setInitialValues(fetchedBucked);
      form.setFieldsValue({ ...fetchedBucked });
    }
  }, [bucket, form, initialValues]);

  return (
    <Spin spinning={loading}>
      <div>
        <AppEntityForm
          elId={bucketId}
          appId={AppConfig.todixId}
          onSubmit={handleSubmit}
          autoComplete="off"
          initialValues={initialValues || {}}
          name="bucketCreator"
          providedForm={form}
          readOnly={!canProceed}
          disabledEditButton={!canProceed}
          disabledSubmitButton={!canProceed}
          style={{ height: `calc(100vh - 240px)` }}
          hideButtons={true}
        >
          {() => {
            return (
              <div>
                <ViewLayout>
                  <Item noStyle shouldUpdate={true}>
                    {({ getFieldsValue }) => {
                      return (
                        <PagePreview
                          values={getFieldsValue()}
                          readOnly={!canProceed}
                          relatedDocuments={relatedDocuments}
                          rangeOfNumbers={rangeOfNumbers}
                        />
                      );
                    }}
                  </Item>
                </ViewLayout>
                <PageViewDrawer
                  closable
                  visible={collapsed}
                  destroyOnClose={false}
                  onClose={() => setCollapsed(false)}
                  placement="right"
                  getContainer={false}
                  mask={false}
                  closeIcon={
                    <Button
                      icon={<ArrowRightOutlined />}
                      type="primary"
                    ></Button>
                  }
                >
                  <RightColumn>
                    <Space direction="horizontal" size="large">
                      <BucketStepType />
                      <BucketStatus />
                    </Space>
                    <SelectedEcosystem
                      disabled={true}
                      appId={AppConfig.todixId}
                      action={'view-purchases' as PURCHASES_ACTION}
                    />
                    <ButtonContainer>
                      <Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const status = getFieldValue('status');
                          const disabled =
                            loading || !['draft', 'cancelled'].includes(status);
                          const draftShown = status === 'draft';
                          return (
                            <>
                              {draftShown && (
                                <Button
                                  type="default"
                                  htmlType="button"
                                  disabled={loading || !canProceed}
                                  onClick={handleCancel}
                                >
                                  {intl.formatMessage({
                                    id: 'button.cancel',
                                    defaultMessage: 'Cancel',
                                  })}
                                </Button>
                              )}
                              {draftShown && (
                                <Space direction="horizontal">
                                  <Button
                                    type="dashed"
                                    htmlType="submit"
                                    disabled={loading || !canProceed}
                                    onClick={() => {
                                      asDraft.current = true;
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: 'button.save.draft',
                                      defaultMessage: 'Save as draft',
                                    })}
                                  </Button>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={disabled || !canProceed}
                                    onClick={() => {
                                      asDraft.current = false;
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: 'button.save',
                                      defaultMessage: 'Save',
                                    })}
                                  </Button>
                                </Space>
                              )}
                              {!draftShown && (
                                <Button
                                  type="default"
                                  htmlType="button"
                                  onClick={goBackToList}
                                >
                                  {intl.formatMessage({
                                    id: 'button.back',
                                    defaultMessage: 'back',
                                  })}
                                </Button>
                              )}
                            </>
                          );
                        }}
                      </Item>
                    </ButtonContainer>
                  </RightColumn>
                </PageViewDrawer>
                {!collapsed && (
                  <PurchaseCreatorSettings
                    onCollapseClick={() => setCollapsed(true)}
                  />
                )}
                <Form.Item name="relatedContactSnapshot" hidden />
                <Form.Item name="relatedContact" hidden />
                <Form.Item name="relatedDocumentId" hidden />
                <Form.Item name="positions" hidden />
                <Form.Item name="draftSaved" hidden />
                <Form.Item name="docNumber" hidden />
              </div>
            );
          }}
        </AppEntityForm>
      </div>
    </Spin>
  );
};

export default BucketCreator;
