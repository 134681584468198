import get from 'lodash.get';
import set from 'lodash.set';
import {
  post,
  get as apiGet,
  getWithId,
  deleteRequest,
  put,
  ApiOptions,
} from '@services/api';
import { firebase } from '@core/services/Firebase';
import momentToFormatString from '@core/helpers/momentToFormatString';
import removeUndefined from '@core/helpers/removeUndefined';
import { emitCustomEvent } from '@core/services';
import {
  Document,
  DocumentsTypesSettings,
  DocumentsTypesSettingsResponse,
  Inbox,
} from '@apps/documents/service';

import { rootStore } from '@core/store';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { ListEvents } from '@components/List';
import { Contact } from '@apps/contacts/services';
import { getFilteredData } from '@services/filtering';
import { IntlShape } from 'react-intl';
import moment from 'moment';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import appConfig, { DOCUMENTS_ACTION } from '@apps/documents';

export * from './namespace';

type GetDocumentsParams = {
  query?: string;
  offset?: number;
  limit?: number;
  fields?: string[];
  filters?: object;
  sort?: string[];
  intl?: IntlShape;
};

type ApiResponse<T extends object = {}> = {
  results: T[];
  info: {
    results: number;
  };
};

export type GetDocuments = (
  action: DOCUMENTS_ACTION,
  params?: GetDocumentsParams,
  intl?: IntlShape,
) => Promise<ApiResponse<Document>>;

const COLLECTION_NAME = 'documents';
//@ts-ignore
export const getDocuments: GetDocuments = (action, p, intl) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 50,
    fields: [
      'id',
      'name',
      'documentUrl',
      'inbox',
      'ecosystem',
      'type',
      'timestamp',
      'category',
      'creationDate',
      'fileDetails',
    ],
    filters: {},
    sort: [],
    ...p,
  };
  // @ts-ignore
  return apiGet(COLLECTION_NAME).then((data: Document[]) => {
    // filter, sort, limit (need info about all records)
    const filterEntries = Object.entries(params.filters);
    const shouldFilter = filterEntries.length;
    const shouldSort = !!params.sort.length;
    const filteredByEcosystems = data.filter((row) =>
      activeEcosystemsIds.includes(row.ecosystem as string),
    );
    const filteredByQuery = filteredByEcosystems.filter((row) =>
      params.fields.some((path) => {
        const fieldValue = get(row, path);
        if (typeof fieldValue !== 'string') {
          return false;
        }
        const queryLower = params.query.toLowerCase();
        return fieldValue.toLowerCase().includes(queryLower);
      }),
    );
    const filtered = shouldFilter
      ? getFilteredData(filteredByQuery, filterEntries, intl)
      : filteredByQuery;
    const sorted = shouldSort
      ? [...filtered].sort((rowA, rowB) => {
          const sortKey = params.sort[0];
          const desc = sortKey.split('').includes('-');
          const path = sortKey
            .split('')
            .filter((c) => c !== '-')
            .join('');
          const valueA = get(rowA, path);
          const valueB = get(rowB, path);
          if (valueA === valueB) {
            return 0;
          }
          if (valueA < valueB) {
            return desc ? 1 : -1;
          } else {
            return desc ? -1 : 1;
          }
        })
      : filtered;
    const choosenFields = sorted.map((row) => {
      const newRow = {};
      params.fields.forEach((path: string): void => {
        const value = get(row, path);
        set(newRow, path, value);
      });
      return newRow;
    });
    const results = choosenFields.length;
    const page = choosenFields.slice(
      params.offset,
      params.offset + params.limit,
    );
    return {
      results: page,
      info: {
        results,
      },
    };
  });
};

export const getContactName = (contact: Contact) =>
  contact.type === 'company'
    ? contact.companyName
    : `${contact.firstName || ''} ${contact.lastName || ''}`;

export const getDocumentsArchived: GetDocuments = async (action, p, intl) => {
  return await getDocuments(
    action,
    {
      ...p,
      filters: {
        ...p?.filters,
        isDraft: {
          filter: 'false',
          filterType: 'text',
          type: 'contains',
        },
        type: {
          filter: 'productPicture',
          filterType: 'text',
          type: 'notContains',
        },
      },
    },
    intl,
  );
};

export const getDocumentsInbox: GetDocuments = (action, p) => {
  return getDocuments(action, {
    ...p,
    filters: {
      ...p?.filters,
      isDraft: {
        filter: 'true',
        filterType: 'text',
        type: 'contains',
      },
    },
  });
};

export const getDocument = (id: string) =>
  getWithId(`${COLLECTION_NAME}/${id}`) as Promise<Document>;

export const getDocumentsByIds = (ids: string[]) =>
  Promise.all(ids.map((id) => getDocument(id)));

export const updateDocument = (
  id: string,
  document: Partial<Document>,
  message?: string,
) =>
  put(
    `${COLLECTION_NAME}/${id}`,
    momentToFormatString({
      ...removeUndefined(document),
      timestamp: moment().valueOf(),
    }),
    {
      message: !!message,
      messageContent: message,
    },
  );

export const resetDocument = async (id: string) => {
  const doc = await getDocument(id);
  const {
    documentUrl,
    fileDetails,
    origin,
    uploadDate,
    uploadingUser,
    ecosystem,
  } = doc;
  return updateDocument(id, {
    isDraft: true,
    documentUrl,
    fileDetails,
    origin,
    uploadDate,
    uploadingUser,
    ecosystem,
  });
};

export const postDocument = (
  document: Partial<Document>,
  options?: ApiOptions,
) =>
  post(
    COLLECTION_NAME,
    momentToFormatString({
      ...removeUndefined(document),
      timestamp: moment().valueOf(),
    }),
    options,
  );

export const deleteDocument = (id: string) =>
  deleteRequest(`${COLLECTION_NAME}/${id}`)?.then((response) => {
    emitCustomEvent<ListEvents>('refreshList');
    return response;
  });

export const getInboxes = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection('documentsInboxes')
    .where('ecosystem', '==', ecosystemId)
    .get()
    .then(getArrayWithIds) as Promise<Inbox[]>;
};

export const getAllInboxes = (action: DOCUMENTS_ACTION) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  return activeEcosystemsIds?.length
    ? (firebase.firestore
        ?.collection('documentsInboxes')
        .where('ecosystem', 'in', activeEcosystemsIds)
        .get()
        .then(getArrayWithIds) as Promise<Inbox[]>)
    : Promise.resolve([]);
};

export const postInbox = (inbox: Partial<Inbox>) =>
  post('documentsInboxes', inbox);

export const updateInbox = (id: string, inbox: Inbox) => {
  return put(`documentsInboxes/${id}`, removeUndefined(inbox));
};

export const getDocumentsTypesSettings = (
  id: string,
): Promise<DocumentsTypesSettingsResponse | null> | undefined => {
  return firebase.firestore
    ?.collection('documentsTypes')
    .where('ecosystem', '==', id)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs[0] ? (docs[0] as DocumentsTypesSettingsResponse) : null;
    });
};

export const updateDocumentsTypesSettings = (
  id: string,
  settings: DocumentsTypesSettings,
) => {
  return put(`documentsTypes/${id}`, removeUndefined(settings));
};

export const getDocumentsByContactAndEcosystem = (
  ecosystem: string,
  relatedContact: string,
  documentType: string,
): Promise<Document[]> => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-documents' as DOCUMENTS_ACTION,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystem)) {
    return Promise.resolve([]);
  }

  return (firebase.firestore as firebase.firestore.Firestore)
    ?.collection(COLLECTION_NAME)
    .where('ecosystem', '==', ecosystem)
    .where('relatedContact', '==', relatedContact)
    .where('isDraft', '==', false)
    .where('type', '==', documentType)
    .get()
    .then(getArrayWithIds) as Promise<Document[]>;
};

export const getUnconnectedDeliveryNotes = (
  ecosystem: string,
  supplierId: string,
): Promise<Document[]> => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-documents' as DOCUMENTS_ACTION,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystem)) {
    return Promise.resolve([]);
  }

  return (firebase.firestore as firebase.firestore.Firestore)
    ?.collection(COLLECTION_NAME)
    .where('ecosystem', '==', ecosystem)
    .where('relatedContact', '==', supplierId)
    .where('isDraft', '==', false)
    .where('type', '==', 'deliveryNote')
    .where('isConnectedToWarehouse', '==', false)
    .get()
    .then(getArrayWithIds) as Promise<Document[]>;
};

export const updateDocumentWarehouseConnection = async (
  documentId: string,
  isConnected: boolean,
) => {
  return updateDocument(documentId, {
    isConnectedToWarehouse: isConnected,
  });
};
