import React from 'react';
import {
  ShopOutlined,
  UnorderedListOutlined,
  FileTextOutlined,
  InboxOutlined,
  EuroOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

const purchaseStepIconMapper: Record<string, React.FC> = {
  draft: ShopOutlined,
  ordered: UnorderedListOutlined,
  proforma: FileTextOutlined,
  delivered: InboxOutlined,
  invoiced: EuroOutlined,
  cancelled: CloseCircleOutlined,
};

export default purchaseStepIconMapper;
