import React, { FC, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Document } from '@apps/documents/service/namespace';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { getBucketsPerDetails } from '@apps/purchases/services/buckets';
import { getDocumentsRelatedWithBucket } from '@apps/purchases/services/buckets';
import moment from 'moment';
import { getContactName } from '@apps/documents/service';

const { Item } = Form;

const Order: FC = () => {
  const { getFieldValue } = useFormContext();
  const [documents, setDocuments] = useState<Document[]>([]);
  const supplierId = getFieldValue('supplier');
  const ecosystem = getFieldValue('ecosystem');

  useEffect(() => {
    const fetchDocuments = async () => {
      if (supplierId && ecosystem) {
        try {
          const buckets = await getBucketsPerDetails({
            ecosystem,
            relatedContact: supplierId,
            stepType: 'ordered',
            status: 'done',
          });

          const allDocuments = await Promise.all(
            buckets.map((bucket) => getDocumentsRelatedWithBucket(bucket)),
          );

          setDocuments(allDocuments.flat());
        } catch (error) {
          console.error('Error fetching orders:', error);
          setDocuments([]);
        }
      } else {
        setDocuments([]);
      }
    };

    fetchDocuments();
  }, [supplierId, ecosystem]);

  return (
    <Item
      name="orderId"
      label={
        <FormattedMessage id="warehouse.create.order" defaultMessage="Order" />
      }
      className="animate__animated animate__slideInRight animate__faster"
    >
      <Select
        placeholder={
          <FormattedMessage
            id="warehouse.create.order.placeholder"
            defaultMessage="Order..."
          />
        }
        disabled={!supplierId || !ecosystem}
        options={documents.map((doc) => ({
          value: doc.id,
          label: `${doc.documentNumber || doc.docNumber || ''} - ${moment(
            doc.creationDate,
          ).format('DD.MM.YYYY')} - ${
            doc.relatedContactSnapshot
              ? getContactName(doc.relatedContactSnapshot)
              : ''
          }`,
        }))}
      />
    </Item>
  );
};

export default Order;
