import { FieldType, LayoutField } from '@apps/documents/service/namespace';
import {
  CommonFieldsLayout,
  SideViewCommonFieldsLayout,
} from '@apps/documents/service/commonFields.layouts';
import { LayoutDestination } from '@apps/documents/service/layouts';

export const ProcessingInvoiceLayout: LayoutField[] = [
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.processinginvoice.docnumber.label',
    rules: [
      {
        required: true,
        message: 'documents.processinginvoice.docnumber.error',
      },
    ],
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
    rules: [
      { required: true, message: 'documents.commonfields.issuingDate.error' },
    ],
    noFutureDate: true,
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.receivingdate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.receivingdate.placeholder',
    rules: [
      {
        required: true,
        message: 'documents.processinginvoice.receivingdate.error',
      },
    ],
    noFutureDate: true,
  },
  {
    fieldName: 'totalNetAmount',
    fieldType: 'moneyInput',
    label: 'documents.processinginvoice.totalnetamount.label',
  },
  {
    fieldName: 'taxAmount',
    fieldType: 'moneyInput',
    label: 'documents.processinginvoice.taxamount.label',
  },
  {
    fieldName: 'totalGrossAmount',
    fieldType: 'moneyInput',
    label: 'documents.processinginvoice.totalgrossamount.label',
  },
  {
    fieldName: 'payments',
    fieldType: 'payments',
    label: 'documents.processinginvoice.payments.label',
    wrapperStyle: {
      gridColumn: '1 / span 2',
    },
  },
  {
    fieldName: 'documentDueDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.documentduedate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.documentduedate.placeholder',
  },
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.processinginvoice.referencenumber.label',
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    placeholder: 'documents.commonfields.sideview.notes',
    wrapperStyle: {
      gridColumn: '1 / span 2',
    },
  },
];

export const ProcessingSummaryInvoiceLayout: LayoutField[] =
  ProcessingInvoiceLayout.map((field) => ({
    ...field,
    fieldType: ['payments', 'costStructure', 'datepicker'].includes(
      field.fieldType,
    )
      ? (field.fieldType as FieldType)
      : 'input',
    placeholder: '',
    rules: [],
    style: {
      border: 0,
    },
  }));

export const InvoiceLayout: LayoutField[] = [
  ...CommonFieldsLayout,
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.invoice.docnumber.label',
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.invoice.receivingdate.label',
    type: 'date',
    placeholder: 'documents.invoice.receivingdate.placeholder',
  },
  {
    fieldName: 'costStructure',
    fieldType: 'costStructure',
    label: 'documents.processinginvoice.coststructure.label',
    readOnly: true,
  },
  {
    fieldName: 'payments',
    fieldType: 'payments',
    readOnly: true,
    label: 'documents.processinginvoice.payments.label',
  },
  {
    fieldName: 'documentDueDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.invoice.documentduedate.label',
    type: 'date',
    placeholder: 'documents.invoice.documentduedate.placeholder',
  },
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.invoice.referencenumber.label',
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];

export const SideViewInvoiceLayout: LayoutField[] = [
  ...SideViewCommonFieldsLayout,
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.invoice.sideview.docnumber.label',
    readOnly: true,
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.invoice.sideview.receivingdate.label',
    type: 'date',
  },
  {
    fieldName: 'costStructure',
    fieldType: 'costStructure',
    label: 'documents.processinginvoice.coststructure.label',
    readOnly: true,
  },
  {
    fieldName: 'payments',
    fieldType: 'payments',
    label: 'documents.processinginvoice.payments.label',
    readOnly: true,
  },
  {
    fieldName: 'documentDueDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.invoice.sideview.documentduedate.label',
    type: 'date',
  },
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.invoice.sideview.referencenumber.label',
    readOnly: true,
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];

const invoiceLayouts: Record<LayoutDestination, LayoutField[]> = {
  detailedForm: InvoiceLayout,
  sideView: SideViewInvoiceLayout,
  processing: ProcessingInvoiceLayout,
  processingSummary: ProcessingSummaryInvoiceLayout,
};

export default invoiceLayouts;
