import React, { FC, useState } from 'react';
import FlowManagementForm from '@apps/warehouse/mainMenu/components/FlowManagementForm';
import { ProductEntry } from '@apps/warehouse/services/namespace';
import ProductEntries from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries';
import AppConfig, { WAREHOUSE_ACTION } from '@apps/warehouse';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

type FlowManagementProps = {
  onSubmit(values: any): void;
  ecosystemId?: string;
  action: WAREHOUSE_ACTION;
};

const FlowManagement: FC<FlowManagementProps> = ({
  onSubmit,
  ecosystemId = null,
  action,
}) => {
  const canCreate = checkUserPermissions(
    rootStore.getState(),
    AppConfig.todixId,
    action,
    ecosystemId || '',
  );
  const [selectedEcosystem, setSelectedEcosystem] = useState<string | null>(
    canCreate ? ecosystemId : null,
  );
  const [products, setProducts] = useState<ProductEntry[]>([]);
  const [count, setCount] = useState(0);

  return (
    <>
      <FlowManagementForm
        entries={products}
        setEntries={setProducts}
        onSubmit={onSubmit}
        onEcosystemSelect={setSelectedEcosystem}
        ecosystemId={ecosystemId || ''}
        action={action}
      />
      {canCreate && (
        <ProductEntries
          entries={products}
          setEntries={setProducts}
          count={count}
          setCount={setCount}
          selectedEcosystem={selectedEcosystem}
        />
      )}
    </>
  );
};

export default FlowManagement;
